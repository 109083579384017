<!-- 学生列表 -->
<!-- 学校花名册 -->
<template>
     <div class="loadpro">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item >文件管理</el-breadcrumb-item>
            <el-breadcrumb-item >批量上传文件</el-breadcrumb-item>
        </el-breadcrumb>
       
      
        <el-upload
                ref="uploadf"
                class="elupload"
                drag
                :limit="1" 
                action=""
                :show-file-list="false"
                :auto-upload="true"
                :before-upload="beforeUpload"
                :http-request="httprequest"
                >
                
          <div class="upload">
            <i style="font-size:80px" class="el-icon-s-claim"></i>
            <p style="font-size:20px">点击上传文件</p>
          </div>
        </el-upload>  
        <el-progress v-show="showprocess" type="line" color="#00cccc" :stroke-width="20" :percentage="progressPercent" class="progress" :text-inside="true"></el-progress>
     </div>
</template>
<script>
import {uploadfile} from '@/api/upload.js'
export default {
    name:'userList',
    components:{
    
    },
    data(){
        return{
            showprocess:false,//进度条
            progressPercent:0
        }
    },
    methods:{
        //
        beforeUpload(file){
           // console.log("上传",file) ;
            var msg=file.name.substring(file.name.lastIndexOf('.')+1);
            const fileType = msg==='zip';
            if(!fileType){
                this.$message({
                type:'warning',
                 message:'上传类型只能是zip格式'
                 })
                 return fileType ;
            }         
                const isLtM = file.size / 1024 / 1024 >1024
                //限制上传的文件大小
                if (isLtM) {
                    this.$message({
                    type:'warning',
                    message:'上传的文件大小不能超过1GB'
                    })
                return !isLtM;
                }   
      },


      httprequest(par){
            var uploadFile = par.file;
            var formData = new FormData();
            formData.append('file',uploadFile);
            //自定义上传进度条 采用 axios自带的 onUploadProgress 来实现
            const uploadProgressEvent = progressEvent => {
              this.progressPercent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
            };
            this.showprocess=true;//开启进度条
            uploadfile(
                formData,
                uploadProgressEvent
            ).then(res=>{
                if(res.data.status == 200){
                    this.$message({
                     message: '上传成功！',
                     type: 'success'
                    });
                  this.$refs.uploadf.clearFiles();
                  this.showprocess=false;//关闭进度条
                }else{
                     this.$message.error(res.data.message);
                }
              
            }).catch(res=>{
                console.log(res)
                this.$message.error(res);
                this.$refs.uploadf.clearFiles();
                this.showprocess=false;
        })
        },
      remove(){},//移除以上传的--暂不需要
       
    }
}
</script>
<style scoped lang="stylus">

//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
/deep/.el-icon-s-claim:before{
    color: #00cccc !important
}
/deep/.progress{
    width: 640px;
    height: 100px;
    margin: 0 auto
   
  
  }
.loadpro
    overflow hidden
.elupload
    width: 1000px !important
    margin: 60px auto !important

</style>
//上传 模块
import  request  from "@/utils/request";

//上传文件
export function uploadfile (parameter,onUploadProgress){
    return request({
      headers:{'Content-Type': 'multipart/form-data'},
      onUploadProgress,
      url:'/api/report-process/batchUpload',
      method:'post',
      data:parameter
    
  })
}


//处理上传文件列表
export function processReportList (parameter){
    return request({
        url:'/api/report-process/selectList',
        method:'post',
        data:parameter
    })
}

//下载原始数据
export function downloadFile (parameter){
    return request({
        headers: {
            'content-type': 'application/json;charset=utf-8',
            },
        responseType: 'blob',
        url:'/api/report-process/downloadFile',
        method:'post',
        data:parameter
    })
}

//立即执行
export function processFile (parameter){
    return request({
        url:'/api/report-process/processFile',
        method:'post',
        data:parameter
    })
}


//删除以上传报告
export function deleteReport (parameter){
    return request({
        url:'/api/report-process/deleteFile',
        method:'post',
        data:parameter
    })
}